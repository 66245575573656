$phone-width: 470px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin smallPhone {
  @media (max-width: #{$phone-width - 1px}) {
    @content;
  }
}

@mixin phone {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin allPhones {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin phonesAndTablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}