@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

:root {
  --font-size: 20px;
  --font-family: 'Spartan', sans-serif;
  --time-font-family: 'Source Code Pro', sans-serif;
  --background-color: #FFFFFF;
  --font-color: #333333;
  --hero-color: #FFFFFF;
  --hr-color: rgba(255, 255, 255, 0.1);
  --page-width: 1220px;
  --footer-background: #262626;
  --content-padding: calc(min(var(--page-width), 100vw) * .1);
  --box-shadow: 1px 1px 6px rgb(53 53 53 / 25%);
  --track-bg-color: #e9f0fd;
  --track-badge-bg: #d5e2fb;
  --active-color: #2162ee;
  --top-bar: 77px;
  --main-gap: 45px;
  --arrow-size: 78px;
  --button-height: 21px;
  --line-thickness: 3px;
  --animation-duration: 0.3s;
}
