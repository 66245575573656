@import "https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;600;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap";
:root {
  --font-size: 20px;
  --font-family: "Spartan", sans-serif;
  --time-font-family: "Source Code Pro", sans-serif;
  --background-color: #fff;
  --font-color: #333;
  --hero-color: #fff;
  --hr-color: #ffffff1a;
  --page-width: 1220px;
  --footer-background: #262626;
  --content-padding: calc(min(var(--page-width), 100vw) * .1);
  --box-shadow: 1px 1px 6px #35353540;
  --track-bg-color: #e9f0fd;
  --track-badge-bg: #d5e2fb;
  --active-color: #2162ee;
  --top-bar: 77px;
  --main-gap: 45px;
  --arrow-size: 78px;
  --button-height: 21px;
  --line-thickness: 3px;
  --animation-duration: .3s;
}

html {
  background-image: url("background.29a66d29.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h1 {
  margin: 0 0 2rem;
}

.page-anchor {
  scroll-margin: 90px;
}

.apply-button {
  background-color: var(--hero-color);
  color: var(--active-color);
  min-width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 30px 0 0 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 0 10px 15px;
  font-size: min(3.5vw, 18px);
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .apply-button {
    min-width: 270px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .apply-button {
    min-width: 55vw;
    margin-top: 30px;
    margin-left: 0;
  }
}

.apply-button:before, .apply-button:after {
  content: "";
  width: 24px;
  height: 23px;
  background-color: var(--hero-color);
  z-index: 2;
  border-radius: 2px;
  position: absolute;
  top: 13px;
  right: 0;
  transform: translateX(50%)scale(1, 1.5)rotate(45deg);
}

.apply-button:before {
  z-index: -1;
  box-shadow: 2px 0 6px #35353540;
}

.apply-button .subtitle {
  font-size: .7em;
}

.apply-button.alt {
  background-color: var(--active-color);
  color: var(--hero-color);
}

.apply-button.alt:before, .apply-button.alt:after {
  background-color: var(--active-color);
}

header .top-bar {
  background-color: var(--footer-background);
  color: var(--hero-color);
  height: var(--top-bar);
  box-shadow: var(--box-shadow);
  width: 100%;
  z-index: 10;
  --pad-min: calc(calc(calc(100vw - var(--page-width)) / 2)  + 70px);
  padding: 0;
  padding-left: max(var(--pad-min), 70px);
  padding-right: max(var(--pad-min), 70px);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  header .top-bar {
    padding-left: 40px;
    padding-right: 40px;
  }
}

header .top-bar .logo, header .top-bar .logo a {
  z-index: 10;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

header .top-bar .logo.mobile {
  display: none;
}

@media (max-width: 767px) {
  header .top-bar .logo {
    display: none;
  }

  header .top-bar .logo.mobile {
    display: flex;
  }
}

header .top-bar button, header .top-bar a, header .top-bar a:active, header .top-bar a:focus, header .top-bar a:visited {
  color: var(--hero-color);
}

.hero h1 {
  margin-bottom: 0;
}

.hero .button-address {
  margin-top: 20px;
}

.only-mobile {
  display: none;
}

@media (max-width: 767px) {
  .only-mobile {
    display: block;
  }

  .only-desktop {
    display: none;
  }
}

.app-menu {
  overflow: hidden;
}

.app-menu .page-nav {
  gap: 20px;
  display: flex;
}

.app-menu .mobile {
  display: none;
}

@media (max-width: 767px) {
  .app-menu .mobile {
    display: block;
  }

  .app-menu .desktop {
    height: auto;
    width: 100%;
    background-color: var(--footer-background);
    transition: transform var(--animation-duration) ease-in-out calc(var(--animation-duration) / 2), box-shadow calc(var(--animation-duration) / 2) linear;
    box-shadow: none;
    flex-direction: column;
    padding: 100px 15px 30px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  .app-menu .desktop > * {
    opacity: 0;
    transition: opacity calc(var(--animation-duration) / 2) linear;
  }

  .app-menu .desktop.open {
    transition: transform var(--animation-duration) ease-in-out, box-shadow calc(var(--animation-duration) / 2) linear calc(var(--animation-duration) / 2);
    box-shadow: var(--box-shadow);
    transform: translateY(0);
  }

  .app-menu .desktop.open > * {
    opacity: 1;
    transition: opacity calc(var(--animation-duration) / 2) linear var(--animation-duration);
  }

  .app-menu .menu-button {
    width: 35px;
    height: var(--button-height);
    cursor: pointer;
    z-index: 10;
    background-color: #0000;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    position: relative;
  }

  .app-menu .menu-button div {
    width: 100%;
    height: var(--line-thickness);
    background-color: var(--hero-color);
    opacity: 1;
    transition: opacity 10ms ease-in-out calc(var(--animation-duration) / 2);
    border-radius: 2px;
  }

  .app-menu .menu-button:after, .app-menu .menu-button:before {
    content: "";
    width: 100%;
    height: var(--line-thickness);
    background-color: var(--hero-color);
    border-radius: 2px;
    position: absolute;
    left: 0;
  }

  .app-menu .menu-button:after {
    animation-duration: var(--animation-duration);
    animation-name: topCloseMenu;
    animation-iteration-count: 1;
    top: 0;
  }

  .app-menu .menu-button:before {
    animation-duration: var(--animation-duration);
    animation-name: bottomCloseMenu;
    animation-iteration-count: 1;
    bottom: 0;
  }

  .app-menu .menu-button.open div {
    opacity: 0;
    transition: opacity 10ms ease-in-out calc(var(--animation-duration) / 2);
  }

  .app-menu .menu-button.open:after {
    transform: translateY(calc(var(--button-height) / 2 - var(--line-thickness) / 2)) rotate(45deg);
    animation-name: topOpenMenu;
  }

  .app-menu .menu-button.open:before {
    transform: translateY(calc(var(--button-height) * -1 / 2 + var(--line-thickness) / 2)) rotate(-45deg);
    animation-name: bottomOpenMenu;
  }
}

.app-menu a, .app-menu a:visited {
  color: #333;
  color: var(--font-color);
  text-decoration: none;
}

@keyframes topOpenMenu {
  0% {
    transform: translateY(0)rotate(0);
  }

  50% {
    transform: translateY(calc(var(--button-height) / 2 - var(--line-thickness) / 2)) rotate(0);
  }

  100% {
    transform: translateY(calc(var(--button-height) / 2 - var(--line-thickness) / 2)) rotate(45deg);
  }
}

@keyframes topCloseMenu {
  0% {
    transform: translateY(calc(var(--button-height) / 2 - var(--line-thickness) / 2)) rotate(45deg);
  }

  50% {
    transform: translateY(calc(var(--button-height) / 2 - var(--line-thickness) / 2)) rotate(0);
  }

  100% {
    transform: translateY(0)rotate(0);
  }
}

@keyframes bottomOpenMenu {
  0% {
    transform: translateY(0)rotate(0);
  }

  50% {
    transform: translateY(calc(var(--button-height) * -1 / 2 + var(--line-thickness) / 2)) rotate(0);
  }

  100% {
    transform: translateY(calc(var(--button-height) * -1 / 2 + var(--line-thickness) / 2)) rotate(-45deg);
  }
}

@keyframes bottomCloseMenu {
  0% {
    transform: translateY(calc(var(--button-height) * -1 / 2 + var(--line-thickness) / 2)) rotate(-45deg);
  }

  50% {
    transform: translateY(calc(var(--button-height) * -1 / 2 + var(--line-thickness) / 2)) rotate(0);
  }

  100% {
    transform: translateY(0)rotate(0);
  }
}

.content-section {
  max-width: var(--page-width);
  padding: 0 var(--content-padding);
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: var(--main-gap);
  margin: auto;
  display: flex;
}

.content-section.main-content {
  flex-direction: column;
  gap: 0;
}

.content-section.main-content h2 {
  margin-bottom: 0;
  font-size: 2em;
}

.content-section.first {
  min-height: 100vh;
  justify-content: center;
  margin-top: 0;
  position: relative;
}

.content-section.first .bg {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: -200px;
  left: 0;
}

.content-section.first .bg img {
  width: 100%;
  opacity: .1;
}

.content-section.first h1 {
  font-size: 3em;
}

@media (max-width: 1023px) {
  .content-section.first h1 {
    font-size: 2em;
  }

  .content-section.first h1 img {
    width: 200px;
  }
}

.content-section ol {
  text-align: start;
}

.content-section ol li {
  margin: 8px 0;
}

.content-section pre {
  background: var(--track-badge-bg);
  border-radius: 4px;
  margin: 0;
  padding: 0 4px;
  display: inline-block;
}

.content-section .centred {
  text-align: center;
}

@media (max-width: 1023px) {
  .content-section {
    flex-direction: column;
    margin: 80px auto;
    padding: 0 15px;
  }

  .content-section .arrow {
    display: none;
  }

  .content-section > h3 {
    max-width: 100%;
  }
}

.content-section.columns {
  flex-direction: column;
  gap: 5px;
}

.content-section > h3 {
  min-width: calc(45% - var(--main-gap)  - var(--arrow-size) / 2);
  margin: 0;
  font-size: min(5vw, 3.2rem);
  display: block;
}

@media (max-width: 1023px) {
  .content-section > h3 {
    white-space: normal;
    font-size: max(1.5rem, 5vw);
  }

  .content-section > h3 > br {
    display: none;
  }
}

.content-section > .title-content {
  min-width: calc(45% - var(--main-gap)  - var(--arrow-size) / 2);
}

.content-section > .title-content > h3 {
  white-space: nowrap;
  margin: 0;
  font-size: min(5vw, 3.5rem);
  display: block;
}

@media (max-width: 1023px) {
  .content-section > .title-content > h3 {
    white-space: normal;
    font-size: max(1.5rem, 5vw);
  }

  .content-section > .title-content > h3 > br {
    display: none;
  }
}

.content-section > h4 {
  margin: 0;
  font-weight: 400;
}

@media (max-width: 767px) {
  .content-section > h4 {
    font-size: 1rem;
  }
}

.content-section .arrow {
  background-image: url("content-arrow.74febd54.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.content-section .arrow div {
  width: var(--arrow-size);
  height: 28px;
}

.content-section .section-p {
  text-align: left;
}

.content-section .form-banner {
  position: initial;
  max-width: 100%;
  width: 100%;
  transform: none;
  box-shadow: 0 0 25px #2162ee80;
}

.tracks {
  min-width: 570px;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
  display: flex;
}

@media (max-width: 767px) {
  .tracks {
    min-width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.track {
  background-color: var(--track-bg-color);
  text-align: left;
  min-width: 310px;
  border-radius: 15px;
  flex: 1;
  padding: 30px;
  position: relative;
}

.track h4 {
  border-left: 3px solid;
  margin-top: 0;
  padding-top: 9px;
  padding-left: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.track:nth-child(1) h4 {
  border-color: #a200ff;
}

.track:nth-child(2) h4 {
  border-color: #74ce6c;
}

.track:nth-child(3) h4 {
  border-color: #f3bd29;
}

.track:nth-child(4) h4 {
  border-color: #ea6953;
}

.track:nth-child(5) h4 {
  border-color: #536aea;
}

.track:nth-child(6) h4 {
  border-color: #e79445;
}

.track p {
  font-size: .8em;
}

.track img {
  position: absolute;
  top: -20px;
  left: 15px;
}

.content-spacing {
  margin-top: 40px;
  margin-bottom: 40px;
}

.content-spacing-first {
  margin-top: 80px;
  margin-bottom: 40px;
}

strong {
  font-weight: 600;
}

footer {
  width: 100%;
  background-color: var(--footer-background);
  z-index: 1;
  font-size: var(--font-size);
  color: var(--hero-color);
  position: relative;
}

footer .footer-content {
  width: 100%;
  max-width: var(--page-width);
  margin: auto;
  padding: 0 40px;
}

footer .footer-content .footer-columns {
  min-height: 400px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 767px) {
  footer .footer-content .footer-columns {
    min-height: auto;
    flex-direction: column;
    justify-content: center;
    padding: 130px 0 60px;
  }
}

footer .footer-content .footer-columns .left {
  text-align: left;
  flex: 1;
  font-size: .74rem;
  display: flex;
}

footer .footer-content .footer-columns .left .left-content {
  text-align: center;
}

@media (max-width: 767px) {
  footer .footer-content .footer-columns .left {
    text-align: center;
    flex: 0;
    font-weight: 400;
  }

  footer .footer-content .footer-columns .left img {
    width: 220px !important;
  }
}

footer .footer-content .footer-columns .right {
  text-align: right;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

footer .footer-content .footer-columns .right p {
  font-size: .74rem;
}

@media (max-width: 767px) {
  footer .footer-content .footer-columns .right {
    text-align: center;
    flex: 0;
    align-items: center;
    margin-top: 40px;
  }

  footer .footer-content .footer-columns .right p {
    font-size: .62rem;
  }
}

footer .footer-content .footer-columns .right .buttons {
  width: 100%;
  justify-content: center;
  gap: 20px;
  display: flex;
}

footer .footer-content .footer-columns .right .icon-a {
  width: 45px;
  height: 45px;
  cursor: pointer;
  color: #0000;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

footer .footer-content .footer-columns .right .icon-a.mail {
  background-image: url("mail.ae287629.svg");
}

footer .footer-content .copyright {
  border-top: 1px solid var(--hr-color);
  padding: 30px 0;
  font-size: 10px;
}

footer a, footer a:active, footer a:focus, footer a:visited {
  color: var(--hero-color);
  text-decoration: none;
}

.brands-list {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.brands-list .brand {
  width: 15%;
  min-width: 133px;
  max-height: 50px;
  text-align: center;
  filter: grayscale();
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  padding: 0 13px;
  transition: filter .1s linear;
  display: flex;
}

.brands-list .brand:hover {
  filter: grayscale(0);
}

.brands-list .brand img {
  width: 100%;
}

.brands-list.less .brand {
  width: 20%;
}

.arrow-button {
  height: 100px;
  cursor: pointer;
  opacity: 0;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  animation: 1s 3s forwards fadein;
  position: absolute;
  bottom: 30px;
}

.arrow-button svg {
  height: 100%;
  display: block;
}

.arrow-button .arrows {
  animation: 1s 3s infinite alternate scrollDown;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

.delegation-info, .delegation-binary, .delegation-keplr, .delegation-ping {
  padding: 30px 0 0;
}

.delegation-binary p {
  text-align: left;
}

.delegation-info {
  width: 100%;
  background-color: var(--track-bg-color);
  border-radius: 30px;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
}

@media (max-width: 767px) {
  .delegation-info {
    flex-direction: column;
  }
}

.delegation-info .infos {
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-left: 25px;
  display: flex;
}

@media (max-width: 767px) {
  .delegation-info .infos {
    width: 100%;
    align-items: center;
    padding-left: 0;
  }
}

.delegation-info .actions {
  width: 50%;
}

@media (max-width: 767px) {
  .delegation-info .actions {
    width: 100%;
    padding: 0 10px;
  }
}

.main-button {
  color: var(--hero-color);
  cursor: pointer;
  background: linear-gradient(90deg, #f812b6 0%, #8819f0 100%);
  border: none;
  border-radius: 30px;
  padding: 15px 45px 15px 20px;
  font-weight: 600;
  text-decoration: none;
  transition: opacity .1s linear;
  position: relative;
}

.main-button.centred {
  padding: 15px 20px;
}

.main-button:active {
  opacity: .6;
}

.main-button svg {
  width: 25px;
  display: inline;
}

.main-button .before-copy {
  opacity: 1;
  transition: opacity .1s linear;
  position: absolute;
  top: 16px;
  right: 15px;
}

.main-button .after-copy {
  opacity: 0;
  transition: opacity .1s linear;
  position: absolute;
  top: 16px;
  right: 15px;
}

.main-button.copied .before-copy {
  opacity: 0;
}

.main-button.copied .after-copy {
  opacity: 1;
}

.button-address {
  max-width: calc(100vw - 40px);
  text-overflow: ellipsis;
  overflow: hidden;
}

code {
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  padding: 7px 14px;
  font-size: .8em;
}

code.block {
  text-align: left;
  display: block;
}

.delegation-keplr ul, .delegation-ping ul {
  text-align: left;
}

.delegation-keplr ul li, .delegation-ping ul li {
  margin: 10px 0;
}

.delegation-keplr img, .delegation-ping img {
  max-height: 500px;
}

.delegation-keplr .columns, .delegation-ping .columns {
  gap: 10px;
  display: flex;
}

/*# sourceMappingURL=index.de27e475.css.map */
