@import 'css/mixins';
@import 'css/variables';

html {
  background-image: url('/img/background.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-position: center;
}

h1 {
  margin: 0 0 2rem;
}

.page-anchor {
  scroll-margin: 90px;
}

.apply-button {
  display: inline-flex;
  text-decoration: none;
  flex-direction: column;
  border: none;
  background-color: var(--hero-color);
  color: var(--active-color);
  padding: 14px 0 10px 15px;
  border-radius: 30px 0 0 30px;
  min-width: 270px;
  position: relative;
  font-weight: 400;
  height: 50px;
  font-size: min(3.5vw, 18px);
  cursor: pointer;
  box-shadow: var(--box-shadow);
  justify-content: center;
  align-items: center;
  @include tablet {
    min-width: 270px;
    margin-top: 30px;
  }
  @include allPhones {
    min-width: 55vw;
    margin-top: 30px;
    margin-left: 0;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    right: 0;
    top: 13px;
    width: 24px;
    height: 23px;
    background-color: var(--hero-color);
    border-radius: 2px;
    transform: translateX(50%)  scale(1, 1.5) rotate(45deg);
    z-index: 2;
  }
  &::before {
    z-index: -1;
    box-shadow: 2px 0px 6px rgb(53 53 53 / 25%);
  }

  .subtitle {
    font-size: .7em;
  }

  &.alt {
    background-color: var(--active-color);
    color: var(--hero-color);
    &::before,
    &::after {
      background-color: var(--active-color);
    }
  }
}

/**

        HEADER:

**/


header {
  .top-bar {
    background-color: var(--footer-background);
    color: var(--hero-color);
    height: var(--top-bar);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--box-shadow);
    width: 100%;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    // background-color: var(--hero-color);
    padding: 0;
    --pad-min: calc(calc(calc(100vw - var(--page-width)) / 2) + 70px);
    padding-left: max(var(--pad-min), 70px);
    padding-right: max(var(--pad-min), 70px);
    @include allPhones {
      padding-left: 40px;
      padding-right: 40px;
    }

    .logo, .logo a {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      gap: 20px;
    }

    .logo {
      &.mobile {
        display: none;
      }

      @include allPhones {
        display: none;
        &.mobile {
          display: flex;
        }
      }
    }

    button, a, a:active, a:focus, a:visited {
      color: var(--hero-color);
    }
  }
}

.hero {
  h1 {
    margin-bottom: 0;
  }
   .button-address {
    margin-top: 20px;
   }
}

/**

        NAV MENU:

**/

.only-mobile {
  display: none;
  @include allPhones {
    display: block;
  }
}


.only-desktop {
  @include allPhones {
    display: none;
  }
}

.app-menu {
  overflow: hidden;
  .page-nav {
    display: flex;
    gap: 20px;
  }
  .mobile {
    display: none;
  }
  @include allPhones {
    .mobile {
      display: block;
    }
    .desktop {
      position: absolute;
      height: auto;
      width: 100%;
      left: 0;
      top: 0;
      background-color: var(--footer-background);
      display: flex;
      flex-direction: column;
      padding: 100px 15px 30px 15px;
      transform: translateY(-100%);
      transition: transform var(--animation-duration) ease-in-out calc(var(--animation-duration) / 2), box-shadow calc(var(--animation-duration) / 2) linear;
      box-shadow: none;
      & > * {
        opacity: 0;
        transition: opacity calc(var(--animation-duration) / 2) linear;
      }
      &.open {
        transform: translateY(0);
        transition: transform var(--animation-duration) ease-in-out, box-shadow calc(var(--animation-duration) / 2) linear calc(var(--animation-duration) / 2);
        box-shadow: var(--box-shadow);
        & > * {
          opacity: 1;
          transition: opacity calc(var(--animation-duration) / 2) linear var(--animation-duration);
        }
      }
    }
    .menu-button {
      display: flex;
      position: relative;
      background-color: transparent;
      border: none;
      width: 35px;
      height: var(--button-height);
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      z-index: 10;

      div {
        width: 100%;
        height: var(--line-thickness);
        background-color: var(--hero-color);
        opacity: 1;
        transition: opacity .01s ease-in-out calc(var(--animation-duration) / 2);
        border-radius: 2px;
      }

      &::after, &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: var(--line-thickness);
        background-color: var(--hero-color);
        left: 0;
        border-radius: 2px;
      }
      &::after {
        top: 0;
        animation-duration: var(--animation-duration);
        animation-iteration-count: 1;
        animation-name: topCloseMenu;
      }
      &::before {
        bottom: 0;
        animation-duration: var(--animation-duration);
        animation-iteration-count: 1;
        animation-name: bottomCloseMenu;
      }

      &.open {
        div {
          opacity: 0;
          transition: opacity .01s ease-in-out calc(var(--animation-duration) / 2);
        }
        &::after {
          transform: translateY(calc((var(--button-height) / 2) - (var(--line-thickness) / 2))) rotate(45deg);
          animation-name: topOpenMenu;
        }
        &::before {
          transform: translateY(calc(((var(--button-height) * -1) / 2) + (var(--line-thickness) / 2))) rotate(-45deg);
          animation-name: bottomOpenMenu;
        }
      }
    }
  }
  a, a:visited {
    color: #333;
    color: var(--font-color);
    text-decoration: none;
  }
}

@keyframes topOpenMenu {
  0%   {
    transform: translateY(0) rotate(0);
  }
  50%  {
    transform: translateY(calc((var(--button-height) / 2) - (var(--line-thickness) / 2))) rotate(0);
  }
  100%  {
    transform: translateY(calc((var(--button-height) / 2) - (var(--line-thickness) / 2))) rotate(45deg);
  }
}

@keyframes topCloseMenu {
  0%   {
    transform: translateY(calc((var(--button-height) / 2) - (var(--line-thickness) / 2))) rotate(45deg);
  }
  50%  {
    transform: translateY(calc((var(--button-height) / 2) - (var(--line-thickness) / 2))) rotate(0);
  }
  100%  {
    transform: translateY(0) rotate(0);
  }
}

@keyframes bottomOpenMenu {
  0%   {
    transform: translateY(0) rotate(0);
  }
  50%  {
    transform: translateY(calc(((var(--button-height) * -1) / 2) + (var(--line-thickness) / 2))) rotate(0);
  }
  100%  {
    transform: translateY(calc(((var(--button-height) * -1) / 2) + (var(--line-thickness) / 2))) rotate(-45deg);
  }
}

@keyframes bottomCloseMenu {
  0%   {
    transform: translateY(calc(((var(--button-height) * -1) / 2) + (var(--line-thickness) / 2))) rotate(-45deg);
  }
  50%  {
    transform: translateY(calc(((var(--button-height) * -1) / 2) + (var(--line-thickness) / 2))) rotate(0);
  }
  100%  {
    transform: translateY(0) rotate(0);
  }
}

/**

        MAIN CONTENT:

**/

h2, h1 {
  /* background-color: #f3ec78;
  background-image: linear-gradient(105.71deg,#bba036 3.9%,#cb57f8 87.25%,#de60fa 98.56%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent; */
}

.content-section {
  margin: auto;
  max-width: var(--page-width);
  padding: 0 var(--content-padding);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: var(--main-gap);

  &.main-content {
    flex-direction: column;
    gap: 0;
    h2 {
      margin-bottom: 0;
      font-size: 2em;
    }
  }

  &.first {
    min-height: 100vh;
    justify-content: center;
    position: relative;
    margin-top: 0;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: -200px;
      z-index: -1;
      img {
        width: 100%;
        opacity: .1;
      }
    }

    h1 {
      font-size: 3em;
      @include phonesAndTablet {
        font-size: 2em;
        img {
          width: 200px;
        }
      }
    }
  }

  ol {
    text-align: start;
    li {
      margin: 8px 0;
    }
  }

  pre {
    display: inline-block;
    background: var(--track-badge-bg);
    padding: 0 4px;
    border-radius: 4px;
    margin: 0;
  }

  .centred {
    text-align: center;
  }

  @include phonesAndTablet {
    flex-direction: column;
    margin: 80px auto;
    padding: 0 15px;
    .arrow {
      display: none;
    }
    & > h3 {
      max-width: 100%;
    }
  }

  &.columns {
    flex-direction: column;
    gap: 5px;
  }

  & > h3 {
    min-width: calc(45% - var(--main-gap) - (var(--arrow-size) / 2));
    font-size: min(5vw, 3.2rem);
    margin: 0;
    display: block;
    @include phonesAndTablet {
      font-size: max(1.5rem, 5vw);
      white-space: normal;
      & > br {
        display: none;
      }
    }
  }
  & > .title-content {
    min-width: calc(45% - var(--main-gap) - (var(--arrow-size) / 2));
    & > h3 {
      font-size: min(5vw, 3.5rem);
      white-space: nowrap;
      margin: 0;
      display: block;
      @include phonesAndTablet {
        font-size: max(1.5rem, 5vw);
        white-space: normal;
        & > br {
          display: none;
        }
      }
    }
  }

  & > h4 {
    margin: 0;
    font-weight: 400;
    @include allPhones {
      font-size: 1rem;
    }
  }

  .arrow {
    background-image: url('/img/content-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    div {
      width: var(--arrow-size);
      height: 28px;
    }
  }

  .section-p {
    text-align: left;
  }

  .form-banner {
    position: initial;
    max-width: 100%;
    width: 100%;

    transform: none;
    box-shadow: 0px 0px 25px rgb(33 98 238 / 50%);
  }
}

/**

        TRACKS:

**/

.tracks {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  min-width: 570px;
  @include allPhones {
    min-width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.track {
  flex: 1;
  background-color: var(--track-bg-color);
  padding: 30px;
  text-align: left;
  position: relative;
  border-radius: 15px;
  min-width: 310px;
  h4 {
    border-left: 3px solid;
    padding-left: 5px;
    padding-top: 9px;
    font-weight: bold;
    margin-top: 0;
    font-size: 1.3em;
  }
  &:nth-child(1)  h4 {
    border-color: #a200ff;
  }
  &:nth-child(2)  h4 {
    border-color: #74ce6c;
  }
  &:nth-child(3)  h4 {
    border-color: #f3bd29;
  }
  &:nth-child(4)  h4 {
    border-color: #ea6953;
  }
  &:nth-child(5)  h4 {
    border-color: #536aea;
  }
  &:nth-child(6)  h4 {
    border-color: #e79445;
  }

  p {
    font-size: 0.8em;
  }

  img {
    left: 15px;
    top: -20px;
    position: absolute;
  }
}

.content-spacing {
  margin-top: 40px;
  margin-bottom: 40px;
}
.content-spacing-first {
  margin-top: 80px;
  margin-bottom: 40px;
}

strong {
  font-weight: 600;
}


/**

        FOOTER:

**/

footer {
  width: 100%;
  background-color: var(--footer-background);
  position: relative;
  z-index: 1;
  font-size: var(--font-size);
  color: var(--hero-color);
  .footer-content {
    width: 100%;
    max-width: var(--page-width);
    padding: 0 40px;
    margin: auto;
    .footer-columns {
      min-height: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include allPhones {
        flex-direction: column;
        justify-content: center;
        min-height: auto;
        padding: 130px 0 60px 0;
      }

      .left {
        text-align: left;
        flex: 1;
        font-size: .74rem;
        display: flex;
        .left-content {
          text-align: center;
        }
        @include allPhones {
          flex: 0;
          font-weight: 400;
          text-align: center;
          img {
            width: 220px !important;
          }
        }
      }

      .right {
        text-align: right;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        p {
          font-size: .74rem;
        }
        @include allPhones {
          flex: 0;
          text-align: center;
          align-items: center;
          margin-top: 40px;

          p {
            font-size: .62rem;
          }
        }

        .buttons {
          display: flex;
          gap: 20px;
          width: 100%;
          justify-content: center;
        }

        .icon-a {
          width: 45px;
          height: 45px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          color: transparent;
          overflow: hidden;
          &.mail {
            background-image: url("/img/mail.svg");
          }
        }
      }
    }
    .copyright {
      font-size: 10px;
      padding: 30px 0;
      border-top: 1px solid var(--hr-color);
    }
  }
  a, a:active, a:focus, a:visited {
    color: var(--hero-color);
    text-decoration: none;
  }
}

.brands-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;

  .brand {
    width: 15%;
    min-width: 133px;
    max-height: 50px;
    text-align: center;
    margin: 24px 0;
    padding: 0 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(100%);
    transition: filter 100ms linear;

    &:hover {
      filter: grayscale(0);
    }

    img {
      width: 100%;
    }
  }

  &.less .brand {
    width: 20%;
  }
}

.arrow-button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  height: 100px;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  opacity: 0;
  animation: fadein 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  svg {
    height: 100%;
    display: block;
  }
  .arrows {
    animation: scrollDown 1s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

@keyframes fadein {
  0% { opacity: 0; }
  100%   { opacity: 1; }
}
@keyframes scrollDown {
  0% { transform: translateY(0); }
  100%   { transform: translateY(10); }
}

.delegation-info,
.delegation-binary,
.delegation-keplr,
.delegation-ping {
  padding: 30px 0 0 0;
}

.delegation-binary p {
  text-align: left;
}

.delegation-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  // border: 1px solid black;
  padding-bottom: 30px;
  margin-top: 20px;
  border-radius: 30px;
  background-color: var(--track-bg-color);

  @include allPhones {
    flex-direction: column;
  }

  .infos {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding-left: 25px;
    @include allPhones {
      padding-left: 0;
      align-items: center;
      width: 100%;
    }
  }
  .actions {
    width: 50%;
    @include allPhones {
      padding: 0 10px;
      width: 100%;
    }
  }
}

.main-button {
  border: none;
  padding: 15px 45px 15px 20px;
  text-decoration: none;
  &.centred {
    padding: 15px 20px 15px 20px;
  }
  border-radius: 30px;
  background: linear-gradient(90deg, rgb(248, 18, 182) 0%, rgb(136, 25, 240) 100%);
  color: var(--hero-color);
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.1s linear;
  position: relative;

  &:active {
    opacity: 0.6;
  }

  svg {
    display: inline;
    width: 25px;
  }

  .before-copy {
    position: absolute;
    right: 15px;
    top: 16px;
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  .after-copy {
    position: absolute;
    right: 15px;
    top: 16px;
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &.copied {
    .before-copy {
      opacity: 0;
    }
    .after-copy {
      opacity: 1;
    }
  }
}
.button-address {
  max-width: calc(100vw - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
}


code {
  font-size: 0.8em;
  background-color: #333;
  border-radius: 3px;
  padding: 7px 14px;
  color: #FFF;
  &.block {
    text-align: left;
    display: block;
  }
}

.delegation-keplr,
.delegation-ping {
  ul {
    text-align: left;
    li {
      margin: 10px 0;
    }
  }
  img {
    max-height: 500px;
  }
  .columns {
    display: flex;
    gap: 10px;
  }
}